<template>
  <div v-if="$store.getters.ready" class="heading-font-family">
    <div class="expand-vertical">
      <Header nav="blog" />

      <section class="container-fluid">
        <div class="container fs-16 blog article">
          <div class="row">

            <div v-if="!article.attributes.image" class="col-lg-8 offset-lg-2">
              <h1>{{ article.attributes.title }}</h1>
              <div class="row mb-4">
                <div class="col-6">
                  <div class="text-muted small">{{ $datefns.utc.format(article.attributes.date, 'MMMM d, yyyy') }}</div>
                </div>
                <div class="col-6">
                  <div class="socialshare mt-0 text-right">
                    <button type="button" class="btn btn-link py-0" @click="socialshare('linkedin')"><i class="fab fa-linkedin fa-lg" title="Share with LinkedIn"></i></button>
                    <button type="button" class="btn btn-link py-0" @click="socialshare('twitter')"><i class="fab fa-twitter fa-lg" title="Share with Twitter"></i></button>
                    <button type="button" class="btn btn-link py-0" @click="socialshare('facebook')"><i class="fab fa-facebook fa-lg" title="Share with Facebook"></i></button>
                    <button type="button" class="btn btn-link py-0" @click="socialshare('reddit')"><i class="fab fa-reddit fa-lg" title="Share with Reddit"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="article.attributes.image" class="col-lg-10 offset-lg-1">
              <h1>{{ article.attributes.title }}</h1>
              <div class="text-muted small mb-4">{{ $datefns.utc.format(article.attributes.date, 'MMMM d, yyyy') }}</div>
              <div class="blog-preview-image featured img mb-4" :style="`background-image: url('${article.attributes.image}');`"></div>
              <div class="socialshare text-center mb-4">
                <div class="btn-group">
                  <button type="button" class="btn btn-white btn-sm px-3 px-md-2 drop-shadow-1 mr-1" @click="socialshare('linkedin')"><i class="fab fa-linkedin fa-2x mr-md-2" title="Share with LinkedIn"></i><span class="d-none d-md-inline-block pr-1">LinkedIn</span></button>
                  <button type="button" class="btn btn-white btn-sm px-3 px-md-2 drop-shadow-1 mr-1" @click="socialshare('twitter')"><i class="fab fa-twitter fa-2x mr-md-2" title="Share with Twitter"></i><span class="d-none d-md-inline-block pr-1">Twitter</span></button>
                  <button type="button" class="btn btn-white btn-sm px-3 px-md-2 drop-shadow-1 mr-1" @click="socialshare('facebook')"><i class="fab fa-facebook fa-2x mr-md-2" title="Share with Facebook"></i><span class="d-none d-md-inline-block pr-1">Facebook</span></button>
                  <button type="button" class="btn btn-white btn-sm px-3 px-md-2 drop-shadow-1 mr-1" @click="socialshare('pinterest')"><i class="fab fa-pinterest fa-2x mr-md-2" title="Share with Pinterest"></i><span class="d-none d-md-inline-block pr-1">Pinterest</span></button>
                  <button type="button" class="btn btn-white btn-sm px-3 px-md-2 drop-shadow-1" @click="socialshare('reddit')"><i class="fab fa-reddit fa-2x mr-md-2" title="Share with Reddit"></i><span class="d-none d-md-inline-block pr-1">Reddit</span></button>
                </div>
              </div>
            </div>
            <div class="col-lg-8 offset-lg-2">
              <div id="articlebody" v-html="article.body"></div>

              <div v-if="article.attributes.tags" class="small py-2 mb-4">
                <i class="fal fa-tag fa-lg mr-2 pr-1 text-muted"></i>
                <span v-for="(tag) in article.attributes.tags" :key="tag"><router-link :to="tagLink(tag)" class="no-link"><label-badge color="1976d2" text="ffffff" :label="tag" class="mr-2" /></router-link></span>
              </div>

              <div v-if="related.length">
                <hr class="my-4">
                <h4 class="mb-4">Related Posts</h4>
                <div class="row widget-list mb-4">
                  <div v-for="(post) in related" :key="post.path" class="col-md-6 mb-2 pb-1 pr-2">
                    <router-link :to="post.path">
                      <div class="widget-holder h-100">
                        <div class="widget-bg h-100">
                          <div class="blog-preview-image" :style="`background-image: url('${post.attributes.image}')`"></div>
                          <div class="widget-body">
                            <div class="text-muted small">{{ $datefns.utc.format(post.attributes.date, 'MMMM d, yyyy') }}</div>
                            <h6 class="mt-2 mb-3">{{ post.attributes.title }}</h6>
                            <div class="small">
                              <i v-if="post.attributes.tags" class="fal fa-tag mr-2 pr-1 text-muted"></i>
                              <span v-for="(tag) in post.attributes.tags" :key="tag"><router-link :to="tagLink(tag)" class="no-link"><label-badge color="1976d2" text="ffffff" :label="tag" class="mr-1 small" /></router-link></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="py-2"></div>
            </div>
          </div>
        </div>
      </section>

    </div>
    <Footer />
  </div>
</template>

<script>
import frontmatter from 'tiny-frontmatter';
import Header from '@/components/NavHeader.vue';
import LabelBadge from '@/components/LabelBadge.vue';
import Footer from '@/components/Footer.vue';

export default {
  data() {
    return {
      article: {},
      related: [],
    };
  },
  methods: {
    tagLink(tag) {
      return `/${this.$route.params.section}/tag/${this.tagPath(tag)}`;
    },
    tagPath(tag) {
      return tag.replace(/[\W_]+/g, '-');
    },
    linkClick(e) {
      const dest = e.target.attributes.href.value;
      if (dest.startsWith('/') && !dest.startsWith('//') && !dest.startsWith('/contents')) {
        e.preventDefault();
        this.$router.push(dest);
      }
    },
    socialshare(service) {
      const title = encodeURIComponent(this.article.attributes.title);
      const link = encodeURIComponent(`https://${this.$config.domain}/${this.$route.params.section}/${this.$route.params.article}`);
      let shareURL = '';
      switch (service) {
        case 'facebook':
          shareURL = `https://facebook.com/sharer/sharer.php?u=${link}`;
          break;
        case 'linkedin':
          shareURL = `https://www.linkedin.com/shareArticle?mini=true&url=${link}`;
          break;
        case 'twitter':
          shareURL = `https://twitter.com/share?url=${link}&text=${title}&hashtags=${this.$config.name}`;
          break;
        case 'reddit':
          shareURL = `https://www.reddit.com/submit?url=${link}&title=${title}`;
          break;
        case 'pinterest':
          if (this.article.attributes.image) {
            shareURL = `http://pinterest.com/pin/create/button/?url=${link}&media=${encodeURIComponent(this.article.attributes.image.startsWith('/') ? `https://${this.$config.domain}${this.article.attributes.image}` : this.article.attributes.image)}&description=${title}`;
          }
          break;
        default:
          return;
      }
      if (shareURL !== '') {
        window.open(shareURL, null, 'width=600, height=400, location=0, menubar=0, resizeable=0, scrollbars=0, status=0, titlebar=0, toolbar=0');
      }
    },
  },
  async mounted() {
    try {
      const a = (await this.$cache.http.get(`/articles/${this.$route.params.section}/${this.$route.params.article}.json`, { headers: { Accept: 'text/html' } })).data;
      if (a instanceof Object && process.env.NODE_ENV === 'production') {
        this.article = a;
      } else {
        await import(/* webpackChunkName: "markdown" */ '@/modules/markdown').then(async (markdown) => {
          const md = markdown.default;
          md.config(this);
          const amd = (await this.$cache.http.get(`/articles/${this.$route.params.section}/${this.$route.params.article}.md`, { headers: { Accept: 'text/html' } })).data;
          if (!amd.startsWith('---\n')) {
            throw new Error(404);
          }
          this.article = frontmatter(amd);
          this.article.body = md.render(this.article.body);
        });
      }
      // set title and meta tags
      document.title = `${this.article.attributes.title} - ${this.$config.pretty_name}`;
      if (this.article.attributes.description) {
        this.$util.createMetaTag('meta', { name: 'description', content: this.article.attributes.description });
      }
      this.$util.createMetaTag('meta', { property: 'og:site_name', content: `The ${this.$config.pretty_name} Blog` });
      this.$util.createMetaTag('meta', { property: 'og:type', content: 'article' });
      this.$util.createMetaTag('meta', { property: 'og:title', content: this.article.attributes.title });
      if (this.article.attributes.image) {
        this.$util.createMetaTag('meta', { property: 'og:image', content: `https://${this.$config.domain}${this.article.attributes.image}` });
      } else {
        this.$util.createMetaTag('meta', { property: 'og:image', content: `https://${this.$config.domain}/img/logo_lg.png` });
      }
      if (this.article.attributes.description) {
        this.$util.createMetaTag('meta', { property: 'og:description', content: this.article.attributes.description });
      }
      this.$util.createMetaTag('meta', { property: 'og:url', content: `https://${this.$config.domain}${this.$route.fullPath}` });
      this.$util.createMetaTag('meta', { property: 'article:published_time', content: this.article.attributes.date });
      // this.$util.createMetaTag('meta', { property: 'article:publisher', content: 'https://www.facebook.com/ACCOUNT' });
      this.$util.createMetaTag('meta', { property: 'twitter:card', content: 'summary_large_image' });
      this.$util.createMetaTag('meta', { property: 'twitter:title', content: this.article.attributes.title });
      if (this.article.attributes.image) {
        this.$util.createMetaTag('meta', { property: 'twitter:image', content: `https://${this.$config.domain}${this.article.attributes.image}` });
      } else {
        this.$util.createMetaTag('meta', { property: 'twitter:image', content: `https://${this.$config.domain}/img/logo_lg.png` });
      }
      if (this.article.attributes.description) {
        this.$util.createMetaTag('meta', { property: 'twitter:description', content: this.article.attributes.description });
      }
      this.$util.createMetaTag('meta', { property: 'twitter:url', content: `https://${this.$config.domain}${this.$route.fullPath}` });
      // this.$util.createMetaTag('meta', { property: 'twitter:site', content: '@ACCOUNT' });
      this.$util.createMetaTag('link', { rel: 'alternate', type: 'application/rss+xml', title: `The ${this.$config.pretty_name} Blog`, href: `https://${this.$config.domain}/rss` });
      // find related articles
      const contents = (await this.$cache.http.get(`/articles/${this.$route.params.section}/contents.json`, { headers: { Accept: 'text/html' } })).data;
      contents.sort((a1, a2) => ((a1.attributes.date < a2.attributes.date) ? 1 : -1));
      if (this.article.attributes.tags) {
        for (let i = 0; i < contents.length && this.related.length < 2; i += 1) {
          if (contents[i].attributes.tags && contents[i].path !== `/${this.$route.params.section}/${this.$route.params.article}`) {
            for (let k = 0; k < this.article.attributes.tags.length; k += 1) {
              for (let n = 0; n < contents[i].attributes.tags.length; n += 1) {
                if (this.article.attributes.tags[k] === contents[i].attributes.tags[n] && contents[i].attributes.image) {
                  this.related.push(contents[i]);
                  break;
                }
              }
              if (this.related.length && this.related[this.related.length - 1].path === contents[i].path) {
                break;
              }
            }
          }
        }
      }
      this.$store.commit('ready', true);
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  updated() {
    // fake router-link so we dont have to include the vue compiler
    const links = document.querySelectorAll('#articlebody a');
    for (let i = 0; i < links.length; i += 1) {
      links[i].addEventListener('click', this.linkClick);
    }
    // render charts
    const charts = document.querySelectorAll('#articlebody canvas.chartjs');
    if (charts.length) {
      import(/* webpackChunkName: "chartjs" */ 'chart.js').then((c) => {
        c.Chart.defaults.global.tooltips.enabled = false; // eslint-disable-line
        c.Chart.defaults.global.title.position = 'bottom'; // eslint-disable-line
        c.Chart.defaults.global.legend.position = 'bottom'; // eslint-disable-line
        for (let i = 0; i < charts.length; i += 1) {
          new c.Chart(charts[i], JSON.parse(charts[i].textContent)); // eslint-disable-line
        }
      });
    }
    // render diagrams
    const diagrams = document.querySelectorAll('#articlebody div.mermaid');
    if (diagrams.length) {
      import(/* webpackChunkName: "mermaid" */ 'mermaid').then((Mermaid) => {
        Mermaid.mermaidAPI.initialize({ theme: 'neutral', startOnLoad: false });
        for (let i = 0; i < diagrams.length; i += 1) {
          // create and insert new element to hold svg
          const el = document.createElement('div');
          diagrams[i].parentNode.insertBefore(el, diagrams[i].nextSibling);
          el.className = `mermaid-chart mermaid-${(diagrams[i].textContent.toLowerCase().split('\n'))[0].replace(/[\W_]+/g, '-')}`;
          // generate and insert svg
          diagrams[i].setAttribute('id', `mermaid-${i}`);
          el.innerHTML = Mermaid.mermaidAPI.render(`mermaid-${i}`, diagrams[i].textContent);
        }
      });
    }
  },
  beforeDestroy() {
    const links = document.querySelectorAll('#articlebody a');
    for (let i = 0; i < links.length; i += 1) {
      links[i].removeEventListener('click', this.linkClick);
    }
  },
  components: {
    Header,
    LabelBadge,
    Footer,
  },
};
</script>
